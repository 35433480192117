<template>
  <div class="page">
    <div class="content-box section">
      <easy-card title="基础信息">
        <icon-button slot="extra" icon="return" @click.native="goBack"/>

        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 标题</template>
            {{ publishData.title }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 使用业务类型</template>
            {{
              publishData.couponType === 0
                  ? "通用"
                  : publishData.couponType === 1
                      ? "停车券"
                      : "洗车券"
            }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 结算方</template>
            {{ publishData.paySettleAccountName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 承担方</template>
            {{ publishData.bearerName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 上架时间</template>
            {{ publishData.putawayDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 下架时间</template>
            {{ publishData.soldoutDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 备注</template>
            {{ publishData.remark }}
          </el-descriptions-item>
        </el-descriptions>
      </easy-card>

      <easy-card title="事件规则">
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 触发事件</template>
            {{ publishData.eventName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 触发条件</template>
            {{ publishData.triggeringConditions }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 是否需注册 </template>
            {{}}
          </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label"> 事件总触发次数</template>
            {{ publishData.couponEventTrigger.triggerSum }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 事件单日触发次数</template>
            {{ publishData.couponEventTrigger.dayTrigger }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 对象触发次数限制</template>
            {{ publishData.couponEventTrigger.objTrigger === 0 ? "活动期间累计" : "活动期间单日" }}
            <span v-if="publishData.couponEventTrigger.objTriggerEnd === undefined ">不限次</span>
            <span
                v-else>{{ publishData.couponEventTrigger.objTriggerStart }}次 — {{ publishData.couponEventTrigger.objTriggerEnd }}次</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 对象触发金额限制</template>
            {{ }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> App分享转赠 </template>
            {{}}
          </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label"> 关联分组</template>
            {{ }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 发券对象</template>
            {{ }}
          </el-descriptions-item>
        </el-descriptions>
      </easy-card>

      <easy-card title="优惠券规则">
        <el-table
            v-loading="isLoading"
            :data="couponPublishRule"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column label="优惠券类型" width="200">
            <template slot-scope="scope">
              {{
                scope.row.ruleType === 0
                    ? "面额券"
                    : scope.row.ruleType === 1
                        ? "时长券"
                        : "免费停车券"
              }}
            </template>
          </el-table-column>
          <el-table-column label="优惠额度" prop="faceAmount" width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.ruleType === 0">{{ parseFloat(scope.row.faceAmount).toFixed(2) }}元</span>
              <span v-if="scope.row.ruleType === 1">{{ scope.row.faceAmount }}分钟</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="最高优惠额度" width="200">
          </el-table-column> -->
          <el-table-column label="单次获券张数" prop="getNumber" width="180">
          </el-table-column>
          <!-- <el-table-column label="使用次数限制" width="200">
          </el-table-column> -->
          <!-- <el-table-column prop="title" label="抵扣方式" width="230">
          </el-table-column> -->
          <el-table-column label="有效期" width="350">
            <template slot-scope="scope">
              <span v-if="scope.row.dateRestrict === 2"
              >领取到当天24点</span
              >
              <span v-if="scope.row.dateRestrict === 1"
              >{{ scope.row.beginDate }} 至 {{
                  scope.row.endDate
                }}</span
              >
              <span v-if="scope.row.dateRestrict === 0"
              >领券后{{ (scope.row.dayNumber) / 24 }}天</span
              >
            </template>
          </el-table-column>
          <el-table-column label="金额限制" prop="title" width="200">
            <template slot-scope="scope">
              大于{{ parseFloat(scope.row.restrictAmount).toFixed(2) }}元
            </template>
          </el-table-column>
          <el-table-column label="适用车场">
            <template slot-scope="scope">
              {{ (JSON.parse(scope.row.parkingLotIds)).join(",") }}
            </template>
          </el-table-column>
        </el-table>
        <!-- <el-descriptions direction="vertical" :column="8" border>
          <el-descriptions-item label="优惠券类型">{{
            publishData.couponPublishRule.ruleType === 0
              ? "面额券"
              : publishData.couponPublishRule.ruleType === 1
              ? "时长券"
              : "免费停车券"
          }}</el-descriptions-item>
          <el-descriptions-item label="优惠额度">{{
            publishData.couponPublishRule.faceAmount
          }}</el-descriptions-item>
          <el-descriptions-item label="最高优惠额度"></el-descriptions-item>
          <el-descriptions-item label="单次获券张数">{{
            publishData.couponPublishRule.getNumber
          }}</el-descriptions-item>
          <el-descriptions-item label="使用次数限制"></el-descriptions-item> -->
        <!-- <el-descriptions-item label="抵扣方式">2</el-descriptions-item> -->
        <!-- <el-descriptions-item label="有效期">
          <span v-if="publishData.couponPublishRule.dateRestrict === '2'"
            >领取到当天24点</span
          >
          <span v-if="publishData.couponPublishRule.dateRestrict === '1'"
            >{{ publishData.couponPublishRule.beginDate }}至{{
              publishData.couponPublishRule.endDate
            }}</span
          >
          <span v-if="publishData.couponPublishRule.dateRestrict === '0'"
            >领券后{{ publishData.couponPublishRule.dayNumber }}天</span
          >
        </el-descriptions-item>
        <el-descriptions-item label="金额限制"
          >大于{{
            publishData.couponPublishRule.restrictAmount
          }}元</el-descriptions-item
        >
      </el-descriptions>
      <el-descriptions :column="1" border>
        <el-descriptions-item>
          <template slot="label"> 适用车场 </template> -->
        <!-- {{ publishData.couponPublishRule.parkingLotIds.join(',') }} -->
        <!-- {{parkingLotIds}}
      </el-descriptions-item>
    </el-descriptions> -->
      </easy-card>

      <easy-card title="执行信息">
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 领取量</template>
            {{ publishData.receviedCount }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 使用量</template>
            {{ publishData.useCount }}
          </el-descriptions-item>
        </el-descriptions>
      </easy-card>
    </div>
  </div>
</template>


<script>
import {getCouponPublishRuleVO} from "@/api/coupon";

export default {
  data() {
    return {
      publishData: [],
      parkingLotIds: [],
      couponPublishRule: [], //优惠券发行规则
      isLoading: false,
    };
  },
  methods: {
    //返回停车订单页面
    goBack() {
      this.$router.back();
    },
    //根据id查询发行详情
    getCouponPublishRuleVO(id) {
      this.isLoading = true;
      getCouponPublishRuleVO(id).then((res) => {
        this.isLoading = false;
        if (res && res.code === 30 && res.result) {
          this.publishData = res.returnObject;
          // const parkingLotIds = JSON.parse(res.returnObject.couponPublishRule.parkingLotIds);
          // this.parkingLotIds = parkingLotIds.join(",");
          this.couponPublishRule = res.returnObject.couponPublishRule;
        }
      });
    },
  },
  mounted() {
    const id = this.$route.query.id;
    this.getCouponPublishRuleVO(id);
  },
};
</script>

<style lang="less" scoped>
.record-tilte {
  margin: 14px 0;
}
</style>
